.main-home {
    background-size: cover;
    height: 90vh;
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.top,
.center,
.bottom {
    width: 100%;
}

.top {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: -100px; 
}

.center {
    font-size: 40px;
    font-weight: bold;
    
}

.bottom {
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
    
}
