.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 20px 20px;
  background-color: #f8f9fa;
  border-bottom: 2px solid #ccc;
}

.navbar-logo {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.navbar-name {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  font-weight: bold;
  color: #2c3e50;
}

.navbar-links {
  display: flex;
  list-style: none;
}

.navbar-links a {
  text-decoration: none;
}

.navbar-btn {
  margin: 0 20px;
  padding: 15px 30px;
  font-size: 20px;
  margin-left: auto;
  background-color: #f8f9fa; 
  color: #000;
  transition: font-size 0.3s ease, font-weight 0.3s ease;
}

.navbar-btn:hover {
  font-size: 28px;
  font-weight: bold;
}