intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.intro-name {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
}

.intro-image {
    max-width: 100%;
    height: auto;
}