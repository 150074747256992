.subjectname {
    width: 100%;
    text-align: center;
    font-size: 32px; 
    font-weight: bold; 
    margin-top: 40px;
    margin-bottom: 40px;
}

.subjectBox {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
}

.year {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #ccc; 
}

.semesterBox {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.semester {
    border-right: 1px solid #ccc;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    text-align: center;
}

.semester2 {
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    text-align: center;
}

.subject {
    width: 25%;
    padding: 10px;
    border-right: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.subject2 {
    width: 25%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.subject3 {
    width: 25%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.subject4 {
    width: 25%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
}