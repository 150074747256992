.mypage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 10px;
    margin: 20px;
}

.my-name {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: #000;
    margin-top: 40px;
    margin-bottom: 40px;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.profile img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
    border: 4px solid #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-info {
    text-align: center;
    width: 100%;
}

.profile-info p {
    margin: 5px 0;
    font-size: 20px;
    color: #000;
    font-weight: bold;
}

.profile-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.profile-buttons button {
    background-color: #000;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.profile-buttons button:hover {
    background-color: #004d40;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff; 
    border: 1px solid #999; 
    border-radius: 50%; 
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    color: #999;
    padding: 0;
}

.close-button:hover {
    background: #f0f0f0;
}

.modal-content h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333; /* 텍스트 색상 */
}

.modal-content input {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.save-button {
    background-color: #000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 98%;
    transition: background-color 0.3s;
    margin-top: 5px
}

.save-button:hover {
    background-color: #004d40;
}

