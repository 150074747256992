.facultyContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.procfessorIntro {
    width: 100%;
    text-align: center;
    font-size: 32px; 
    font-weight: bold; 
    margin-top: 40px;
    margin-bottom: 30px;
}

.professorBox {
    width: calc(40%);
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    margin: 10px;
}
