.newsname {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 30px;
}

.post-container {
    margin-bottom: 10px;
    position: relative;
    text-align: center; /* 게시글 입력 폼과 버튼을 가운데 정렬합니다. */
}

.post-input-container {
    display: flex;
    flex-direction: column; /* 수직으로 배치 */
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.post-title-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px; /* 제목과 본문 사이에 간격 추가 */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.post-input {
    width: 100%;
    padding: 10px;
    height: 100px; /* 높이를 높여서 더 많은 텍스트 입력을 가능하게 */
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    font-size: 14px;
}

.post-button {
    font-size: 16px;
    width: 100px;
    padding:  8px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.post-list {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

.post {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    border: 2px solid #eee;
    border-radius: 5px;
}

.post-date {
    font-size: 16px;
    color: #666;
}

.post-text {
    font-size: 16px;
    margin-bottom: 10px;
}

.post-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.post-content {
    background-color: #fff;
    border: 2px solid #eee;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 5px;
    position: relative;
}

.delete-post-button {
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    top: 5px;
    right: 5px;
}

.comment-input-container {
    margin-top: 10px;
    text-align: right;
}

.comment-input-container input[type="text"] {
    width: calc(100% - 100px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
}

.comment-input-container button[type="submit"] {
    width: 100px;
    padding: 8px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.comment-list {
    margin-top: 10px;
}

.comment {
    background-color: #fff;
    border: 2px solid #eee;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    position: relative;
    min-height: 30px;
    display: flex;
    align-items: center; /* 수직 가운데 정렬 */
}

.delete-comment-button {
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    top: 50%; /* 댓글의 세로 중앙 위치 */
    transform: translateY(-50%); /* 댓글 높이의 절반만큼 위로 이동 */
    right: 5px;
}

.post-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.write-new-post-button {
    font-size: 16px;
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px auto 0; /* Center horizontally and add top margin */
    display: block; /* Ensure the button takes up the full width of its container */
}