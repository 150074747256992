* {
    padding: 0;
    margin: 0;
    border: none;
}

body {
    font: 14px "Roboto", sans-serif;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.login-wrapper {
    width: 400px;
    height: 350px;
    padding: 15px;
    box-sizing: border-box;
}

.login-wrapper > h2 {
    text-align: center;
    font-size: 24px;
    color: black;
    margin-bottom: 20px;
}

#login-form > input {
    width: 100%;
    height: 48px;
    background-color: #f8f8f8;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 16px;
    border-radius: 6px;
}

#login-form > input::placeholder {
    color: #d2d2d2;
}

#login-form > input[type="submit"] {
    color: #fff;
    background-color: black;
    font-size: 16px;
    margin-top: 20px;
    height: 48px;
}

#login-form > input[type="checkbox"] {
    display: none;
}

#login-form > label {
    color: #999999;
    font-size: 16px;
}
